/**
 * @file It contains scripts for groups overview.
 */
import { mapGetters, mapActions, mapMutations } from "vuex"
import { getListOfStrings } from "@/utils"
import { getFootersForTable } from "@/utils/table"
import { TABLE_NAMES } from "@/constants"
import  ThemisGroupAdd  from "@/components/group/add"

export default {
  name      : "Groups",
  components: {
    ThemisGroupAdd
  },
  data() {
    return {
      filters             : undefined,
      search              : undefined,
      showAddGroupDialog  : false,
      isGroupNameDuplicate: undefined,
      newlyAddedGroup     : {
        name       : undefined,
        description: undefined
      }
    }
  },
  methods: {
    ...mapActions({
      addGroup: "groups/addGroup",
      notify  : "shared/notify"
    }),
    ...mapMutations({
      setGroupAddError: "groups/setGroupAddError"
    }),
    /**
     * This method is used custom filtering for vuetify data table.
     * This will be called for every cell value in the table.
     * @param {*} value is content of each cell in a the table
     */
    customFilterForTable(value) {
      return this.filters.findIndex(element => value && value.toString().includes(element)) + 1
    },
    handleAddGroupEvent(group) {
      this.newlyAddedGroup.name = group.name
      this.addGroup(group)
    }
  },
  computed: {
    ...mapGetters({
      groups       : "groups/groups",
      users        : "users/users",
      groupsUsers  : "groups/groupsUsers",
      isAddingGroup: "groups/isAddingGroup",
      isGroupAdded : "groups/isGroupAdded",
      groupAddError: "groups/groupAddError"
    }),
    groupsForTable: function() {
      return this.groups.map(group => ({
        id           : group.id,
        name         : group.name,
        numberOfUsers: this.groupsUsers[group.id]?.length || 0
      }))
    },
    headersForTable() {
      return this.$TABLES.GROUPS.headers.map(header => {
        return {
          ...header, ...{
            text: this.$t(header.text)
          }
        }
      })
    },
    footersForTable() {
      return getFootersForTable(TABLE_NAMES.GROUPS, this.$t.bind(this))
    },
    itemsForSearch() {
      return getListOfStrings(this.groupsForTable, ["id"])
    }
  },
  watch: {
    filters: function(newFilters) {
      this.search = newFilters ? newFilters.toString() : undefined
    },
    isGroupAdded: {
      handler: function(value) {
        if (value) {
          this.showAddGroupDialog = false
          this.notify({
            type      : "success",
            text      : "432",
            parameters: {
              name: this.newlyAddedGroup.name
            }
          })
          this.newlyAddedGroup.name = undefined
        }
      }
    }
  }
}